import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import currencyFormatter from "../../utils/currencyFormatter";

const SingleProduct = () => {
    const { state } = useLocation();
    const product = state.product;
    let [quantity, setQuantity] = useState(1);
    let [photoIndex, setPhotoIndex] = useState(0);
    const [productPhotos, setProductPhotos] = useState([]);
    const handleQuantity = (qty) => {
        setQuantity(qty > 0 ? qty : 1);
    }
    const formatPhotos = () => {
        let subArray = [];
        let count = 0;
        let result = [];
        let photos = product.photos;
        for (let i = 0; i < photos.length; i++) {
            if (count === 3) {
                count = 0;
                result.push(subArray);
                subArray = [];
            }
            subArray.push(photos[i]);
            ++count;
        }
        result.push(subArray);
        setProductPhotos(result);
        window.scrollTo(0, 0);

    }
    useEffect(formatPhotos, [product.photos]);
    return (

        <section className="bg-light">
            <div className="container pb-5">
                <div className="row">
                    <div className="col-lg-5 mt-5">
                        <div className="card mb-3">
                            <img className="card-img img-fluid" src={product.photos[photoIndex].imageUrl} alt="" id="product-detail" style={{ width: 'auto', height: '350px' }} />
                        </div>
                        <div className="row">

                            <div className="col-1 align-self-center">
                                <a href="#multi-item-example" role="button" data-bs-slide="prev">
                                    <i className="text-dark bi bi-chevron-left"></i>
                                </a>
                            </div>
                            <div id="multi-item-example" className="col-10 carousel slide carousel-multi-item" data-bs-ride="carousel">
                                <div className="carousel-inner product-links-wap" role="listbox">
                                    {

                                        productPhotos.map((photo, index) => (
                                            <div key={index} className="carousel-item active">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <Link className="" to="/single" state={{ product }} onClick={() => setPhotoIndex((index * 3) + 0)}>
                                                                <img className="card-img img-fluid" src={photo[0]?.imageUrl} alt={`ProductPhoto ${index}`} style={{ width: '100px', height: '80px' }} />
                                                            </Link>
                                                        </div>
                                                        {
                                                            photo[1]?.imageUrl &&
                                                            <div className="col-4">
                                                                <Link className="" to="/single" state={{ product }} onClick={() => setPhotoIndex((index * 3) + 1)}>
                                                                    <img className="card-img img-fluid" src={photo[1].imageUrl} alt={`ProductPhoto ${index}`} style={{ width: '100', height: '80px' }} />
                                                                </Link>
                                                            </div>
                                                        }
                                                        {
                                                            photo[2]?.imageUrl &&
                                                            <div className="col-4">
                                                                <Link className="" to="/single" state={{ product }} onClick={() => setPhotoIndex((index * 3) + 2)}>
                                                                    <img className="card-img img-fluid" src={photo[2].imageUrl} alt={`ProductPhoto ${index}`} style={{ width: '100', height: '80px' }} />
                                                                </Link>
                                                            </div>
                                                        }


                                                    </div>
                                                </div>
                                            </div>

                                        ))

                                    }
                                </div>
                            </div>
                            <div className="col-1 align-self-center">
                                <a href="#multi-item-example" role="button" data-bs-slide="next">
                                    <i className="text-dark bi bi-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-5">
                        <div className="card">
                            <div className="card-body">
                                <p className="fs-4 fw-bold">{product.name}</p>
                                {/* <p className="fs-5 fw-bold py-2">{currencyFormatter(product.price)}
                                    <small className=" ms-6 text-muted fs-6">
                                        <s>{product.discountedPrice > 0 ? `${currencyFormatter(product.price)}` : ""}</s></small>
                                </p> */}
                                <p className="fs-5 fw-bold py-2"><small className="fw-bold">{product?.discountedPrice > 0 ? currencyFormatter(product.discountedPrice) : currencyFormatter(product.price)}</small>&nbsp;&nbsp;
                                    <small className="text-muted fs-6"><s>{product.discountedPrice > 0 ? `${currencyFormatter(product.price)}` : ""}</s></small></p>

                                {product?.description && <>
                                    <h5 className="fw-bold">Description:</h5>
                                    <p>{product.description}</p>
                                </>
                                }
                                {Object.keys(product.specifications).length > 0 && <>
                                    <h5 className="fw-bold">Specification:</h5>
                                    <ul className="list-unstyled pb-3">
                                        {
                                            product.specifications.map((spec, index) => {
                                                return (
                                                    <span key={index}>
                                                        <li className="me-3 mb-2">{spec.property.charAt(0).toUpperCase() + spec.property.slice(1)}
                                                            {(spec.value !== "" && spec.value !== null) ? <span>: {spec.value}</span> : ''}</li>
                                                    </span>
                                                )
                                            }
                                            )
                                        }
                                    </ul>
                                </>
                                }
                                <input type="hidden" name="product-title" value="Activewear" />
                                <div className="row">
                                    <div className="col-auto">
                                        <ul className="list-inline pb-3">
                                            <li className="list-inline-item text-right">
                                                Quantity
                                                <input type="hidden" name="product-quanity" id="product-quanity" value="1" />
                                            </li>
                                            <li className="list-inline-item"><button type="button" className="btn bg-success text-white" onClick={() => handleQuantity(--quantity)} >-</button></li>
                                            <li className="list-inline-item"><span className="badge bg-light text-dark" >{quantity}</span></li>
                                            <li className="list-inline-item"><button type="button" className="btn bg-success text-white" onClick={() => handleQuantity(++quantity)}>+</button></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col d-grid">
                                        <button type="submit" className="btn btn-success btn-lg" name="submit" value="addtocard">Add To Cart</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
export default SingleProduct;