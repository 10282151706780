import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "axios";

// const baseURL = "http://localhost:8006/api/";   
const baseURL = "https://dzz2gwfjm4fx2.cloudfront.net/api/";   

export const useFetchCategories = (page) =>{
    const categories = () => axios.get(`${baseURL}category`).then((res) => res.data.body).catch(err =>console.log(err))

    return useQuery({
        queryKey : ["categories",page],
        queryFn : categories
    })

}

// export const useFetchAllProducts  = ()=>{
//     const fetchProducts = () => axios.get(`${baseUrl}product`).then(res => res.data.body).then(err => err);
//     return useQuery({
//         queryKey:["products"],
//         queryFn: fetchProducts
//     })
// }

export const useFetchAllProducts = (categoryCode, page, size) => {
    let params = {
        categoryCode: categoryCode,
        page: page,
        size: size
    }

    const fetchProducts = () => axios.get(`${baseURL}product`, { params })
        .then(res => res.data.body)
        .catch(err => console.log(err))

    return useQuery({
        queryKey: ["products", params?.page, params?.categoryCode],
        queryFn: fetchProducts,
        placeholderData: keepPreviousData,
    })

}
