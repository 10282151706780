const TopBar = () => {
    return (
        <div className="container-fluid bg bg-success">
            <div className="container">
            <ul className="nav d-flex justify-content-end">
                <li className=''>
                    <i className="bi bi-telephone me-2 text-white"></i>
                    <small className="text-white fw-light">Whatsapp To Order</small><br />
                    <span className="text-white fw-bold">(+234)701 502 8065</span>
                </li>
            </ul>
            </div>
        </div>
    )
}
export default TopBar;