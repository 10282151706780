import { NavLink } from "react-router-dom";
import { useFetchCategories } from "../../services/ApiClient";

const Footer = () => {
    const {data : categories} = useFetchCategories();

    return (
        <div>
            <footer class="bg-light" id="tempaltemo_footer">
                <div class="container">
                    <div class="row">

                        <div class="col-md-4 pt-5">
                            <h3 class="h3 text-dark border-bottom pb-3 border-light logo">Orantoor Place </h3>
                            <ul class="list-unstyled text-dark footer-link-list ">
                                <li>
                                    <i className="fas fa-map-marker-alt fa-fw fs-6"></i>
                                    <li style={{marginBottom:'-15px'}} className="fs-6">1 Favour Close,Pakuro,</li> 
                                    <li className="fs-6">Mowe, Ogun state</li>
                                </li>
                                <li>
                                    <i className="bi bi-telephone"></i>
                                    <i className="fs-6">08032050088</i>
                                </li>
                                <li>
                                    <i class="fa fa-envelope fa-fw"></i>
                                    <a class="text-decoration-none text-dark" href="/">info@orantoor.com</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-md-4 pt-5">
                            <h3 class="h3 text-dark border-bottom pb-3 border-light">Categories</h3>
                            <ul class="list-unstyled text-dark footer-link-list">
                                {
                                    categories?.content?.map(category =>
                                        (<li><NavLink to={'/product'} state={{category:category}} 
                                         className="text-decoration-none text-dark fs-6">{category.name}</NavLink></li>))
                                }
                                
                            </ul>
                        </div>

                        <div className="col-md-4 pt-5">
                            <h3 className="h3 text-dark border-bottom pb-3 border-light">Further Info</h3>
                            <ul className="list-unstyled text-light footer-link-list">
                                <li><a className="text-decoration-none text-dark fs-6" href="/">Shop</a></li>
                                {/* <li><a className="text-decoration-none text-dark fs-6" href="/">About Us</a></li> */}
                                <li><a className="text-decoration-none text-dark fs-6" href="/">Contact</a></li>
                            </ul>
                        </div>

                    </div>

                    <div class="row text-body mb-4">
                        <div class="col-12 mb-3">
                            <div class="w-100 my-3 border-top border-light"></div>
                        </div>
                        {/* <div class="col-auto me-auto">
                            <ul class="list-inline text-left footer-icons">
                                <li class="list-inline-item border border-light rounded-circle text-center">
                                    <a class="text-light text-decoration-none" target="_blank" rel="noreferrer" href="http://facebook.com/"><i class="fab fa-facebook-f fa-lg fa-fw"></i></a>
                                </li>
                                <li class="list-inline-item border border-light rounded-circle text-center">
                                    <a class="text-light text-decoration-none" target="_blank" rel="noreferrer" href="https://www.instagram.com/"><i class="fab fa-instagram fa-lg fa-fw"></i></a>
                                </li>
                                <li class="list-inline-item border border-light rounded-circle text-center">
                                    <a class="text-light text-decoration-none" target="_blank" rel="noreferrer" href="https://twitter.com/"><i class="fab fa-twitter fa-lg fa-fw"></i></a>
                                </li>
                                <li class="list-inline-item border border-light rounded-circle text-center">
                                    <a class="text-light text-decoration-none" target="_blank" rel="noreferrer" href="https://www.linkedin.com/"><i class="fab fa-linkedin fa-lg fa-fw"></i></a>
                                </li>
                            </ul>
                        </div> */}
                        {/* <div class="col-auto">
                            <label class="sr-only" for="subscribeEmail">Email address</label>
                            <div class="input-group mb-2">
                                <input type="text" class="form-control bg-dark border-light" id="subscribeEmail" placeholder="Email address"/>
                                    <div class="input-group-text btn-success text-light">Subscribe</div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div class="w-100 bg-success py-3">
                    <div class="container">
                        <div class="row pt-2">
                            <div class="col-12">
                                <p class="text-left text-light" fs-6>
                                    Copyright &copy; 2024 Orantoor Place
                                    | Designed by <a  href="/" target="_blank" rel="noreferrer">Orantoor Solutions Ltd</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        </div>
    )
}
export default Footer;