import { useFetchCategories } from "../../services/ApiClient";
import SideMenu from "./SideMenu";

const SideBar = ({handleCategoryChange}) => {
    const {data : categories} = useFetchCategories();
   
    return (
        <div className="col-lg-3 bg-light overflow-auto" style={{height:'80vh'}} >
            <h1 className="h3 pt-3 pb-3">CATEGORIES</h1>
            <ul className="list-unstyled templatemo-accordion" >
                {
                    categories?.content?.map(category => {
                        return  <SideMenu key={category.id} category={category} handleCategoryChange={handleCategoryChange}/>
                    })
                }
            </ul>
        </div>
    )
}

export default SideBar;