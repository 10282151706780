import { NavLink } from "react-router-dom";
import CategoryDropdown from "./CategoryDropdown";
import './MenuBar.css';

const MenuBar = ({ page }) => {

    return (
        <nav className="navbar navbar-expand-lg bar">
            <div className="container-fluid bg bg-light">
                {/* remove this div tag when search has been implemented */}
                <div className="d-md-flex">
                    <span className="ms-2"><a className="navbar-brand fw-bold h2 text-success" href="/home">ORANTOOR</a></span>
                    <ul className="nav mb-2 mb-lg-0">
                        {/* <Menu menuName="Categories" path="/category" /> */}

                        <CategoryDropdown page={page} />
                    </ul>
                </div>
                {/* <form className="d-flex" role="search">
                    <div className="input-group">
                        <input className="form-control col-lg-4 bg-light" type="search" placeholder="Search Products" aria-label="Search" />
                        <button className="btn btn-success text-white" type="submit">Search</button>
                    </div>
                </form> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="">
                    <nav className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="nav me-auto mb-2 mb-lg-0">

                            <NavLink to={'/product'} state={{ category: { code: '', name: 'Shop' } }} className="nav-link text-black"><span>Shop</span></NavLink>
                            {/* <NavLink className="nav-link text-black"><span>Account</span></NavLink> */}

                            {/* <Menu menuName="Account" path="/account" /> */}
                            {/* <Menu menuName="AdminLogin" path="/adminlogin" /> */}
                            <li>
                                <a className="nav-link text-black" href="/cart">
                                    Cart <span className="border rounded-circle bg-secondary text-white">0</span>
                                </a>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </nav>
    )
}
export default MenuBar;