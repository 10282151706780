import { NavLink } from "react-router-dom";
import { useFetchCategories } from "../../services/ApiClient";
import "./CategoryDropdown.css";

const CategoryDropdown = ({page}) => {
    const {data,} = useFetchCategories(page);
    
    return (

        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-dark" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Categories
            </a>
            <ul className="dropdown-menu">
                {
                    data?.content && data.content.map(category =>{
                        return <NavLink to={"/product"}  state={{ category: category }} className='nav-link text-body fs-6' key={category.id} >{category.name}</NavLink>
                    })
                }
                {/* <li><a className="dropdown-item" href="/">Action</a></li>
                <li><a className="dropdown-item" href="/">Another action</a></li> */}
                {/* <li>
                    <hr className="dropdown-divider"/>
                </li> */}
                {/* <li className="nav-item dropend">
                    <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Dropdown
                    </a>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="/">Action</a></li>
                        <li><a className="dropdown-item" href="/">Another action</a></li>
                        <li>
                            <hr className="dropdown-divider"/>
                        </li>
                        <li className="nav-item dropend">
                            <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Dropdown
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="/">Action</a></li>
                                <li><a className="dropdown-item" href="/">Another action</a></li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li className="nav-item dropend">
                                    <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Dropdown
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="/">Action</a></li>
                                        <li><a className="dropdown-item" href="/">Another action</a></li>
                                        <li>
                                            <hr className="dropdown-divider"/>
                                        </li>
                                        <li><a className="dropdown-item" href="/">Something else here</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li> */}
            </ul>
        </li>
    )
}
export default CategoryDropdown;