import { NavLink } from "react-router-dom";

const SideMenu = ({ category, handleCategoryChange, icon }) => {
    return (
        <li className="pb-3 nav-link dropdown-item" >
            <i className={icon}></i>
            <NavLink className="nav-link dropdown-item ps-3 fs-6" onClick={()=>handleCategoryChange(category?.code,category?.name)}>{category?.name}</NavLink>
        </li>

            // {/* subMenu is yet to be implemented */ }
    // {
    //     category?.subMenu && <>
    //         <a className="collapsed d-flex justify-content-between h4 text-decoration-none" href="/">
    //             {category?.name}
    //             <i className="fa fa-fw fa-chevron-circle-down mt-1"></i>
    //         </a>
    //         <ul className="collapse show list-unstyled pl-3">
    //             <li><a className="text-decoration-none" href="/" >Men</a></li>
    //             <li><a className="text-decoration-none" href="/">Women</a></li>
    //         </ul>
    //     </>
    // }
        // </li>
    )
}

export default SideMenu;