import MenuBar from "./navbar/MenuBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Product from "./shop/Product";
import './home/Custom.css';
import SingleProduct from "./shop/SingleProduct";
import Footer from "./footer/Footer";
import TopBar from "./navbar/TopBar";
const Main = () => {

    return (
        <BrowserRouter>
        <TopBar/>
            <MenuBar page={1} />
            <div>
                <div className="container">
                    <div className="row">
                        {/* <section className="col-12 " style={{height:'50vh'}}>
                        <span>Carousel</span>
                    </section> */}
                        <div className="">
                            <Routes>
                                {/* <Route path='/home' element={<Home />}></Route> */}
                                <Route path='/' element={<Product />}></Route>
                                <Route path='/product' element={<Product />}></Route>
                                <Route path='/single' element={<SingleProduct />}></Route>
                            </Routes>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        </BrowserRouter >

    )


}

export default Main;