
import ProductCard from "./ProductCard";

const ProductContent = ({products,isLoading,categoryName})=>{
    return(
            <div className="col-lg-9">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="list-inline shop-top-menu pb-3 pt-1 bg bg-light">
                                <li className="list-inline-item">
                                    <h3 className="fw-bold text-dark text-decoration-none pt-2 mr-3">{categoryName!==""?categoryName:'Shop'}</h3>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="col-md-6 pb-4">
                            <div className="d-flex">
                                <select className="form-control">
                                    <option>Featured</option>
                                    <option>A to Z</option>
                                    <option>Item</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        {isLoading && <div>Loading...</div>}
                        {
                            products?.content?.map(product => {
                                if (product.photos[0])
                                    return <ProductCard key={product.id} product={product} />
                                return null;
                            })

                        }
                    </div>
                </div>
        
    )
}
export default ProductContent;