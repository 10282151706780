import { Link } from "react-router-dom";
import currencyFormatter from "../../utils/currencyFormatter";

const ProductCard = ({ product }) => {
    return (
        <div key={product?.id} className="col-md-3 pb-5" >
            <div className="card mb-4 product-wap rounded-0 border border-light"  >
                <div className="card rounded-0 border-0 p-3" style={{ margin: '-10px' }}  >
                    <img className="card-img border-0 rounded-2 img-fluid bg bg-white " alt="ProductCaption" src={product.photos[0].imageUrl} style={{ width: 'auto', height: '250px' }} />
                    <div className="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                        <ul className="list-unstyled">
                            {/* <li><a className="btn btn-success text-white" ><i className="far fa-heart"></i></a></li> */}
                            <li><Link to="/single" state={{ product }} className="btn btn-success text-white mt-2" ><i className="bi bi-eye"></i></Link></li>
                            <li><Link className="btn btn-success text-white mt-2" ><i className="bi bi-cart"></i></Link></li>
                        </ul>
                    </div>
                    <div className="" >
                        <a href="shop-single.html" className=" text-decoration-none"><h5>{product?.name}</h5></a>

                        <p><h5 className="fw-bold">{product?.discountedPrice > 0 ? currencyFormatter(product.discountedPrice) : currencyFormatter(product.price)}</h5>
                            <small className="text-muted fs-6"><s>{product.discountedPrice > 0 ? `${currencyFormatter(product.price)}` : ""}</s></small></p>
                    </div>
                </div>
                {/* <div className="card-body" >
                    <div className="bg bg-white" style={{margin:'-10px'}}>
                        <a href="shop-single.html" className="h3 text-decoration-none"><small className="">{product?.name}</small></a>

                        <p className="text-center mb-0"><small className="fw-bold">{product?.discountedPrice > 0 ? currencyFormatter(product.discountedPrice) : currencyFormatter(product.price)}</small>&nbsp;&nbsp;
                            <small className="text-muted fs-6"><s>{product.discountedPrice > 0 ? `${currencyFormatter(product.price)}` : ""}</s></small></p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ProductCard;