import { useEffect, useState } from 'react';
import { useFetchAllProducts } from '../../services/ApiClient';
import '../home/Custom.css'
import ProductContent from "./ProductContent";
import SideBar from './SideBar';
import { useLocation } from 'react-router-dom';

const Product = () => {
    const [categoryCode, setCategoryCode] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const { data: products, isLoading } = useFetchAllProducts(categoryCode);

    const { state } = useLocation();
    useEffect(() => {
        if (state?.category) {
            setCategoryCode(state.category.code);
            setCategoryName(state.category.name);
            window.scrollTo(0, 0);
        }
    },[state])


    const handleCategoryChange = (code, name) => {
        setCategoryCode(code);
        setCategoryName(name);
    }

    return (
        <div className="container py-5">
            <div className="row">

                <SideBar handleCategoryChange={handleCategoryChange} />
                <ProductContent products={products} isLoading={isLoading} categoryName={categoryName} />
            </div>
        </div>
    )
}

export default Product;
